import { useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { L10N } from 'global/localization'
import { CONSTANT } from 'global/constant'
import { LayoutType } from 'global/types/enums'
import { useAppDispatch } from 'store/store'
import { useGetPaymentGroupListQuery, useSubmitOrderMutation } from 'store/subscription'
import { getOrderState } from 'store/slices/order'
import { setPaymentUrl } from 'store/slices/payment'
import { DataHandler } from 'shared/components/data-handler'
import Icon from 'shared/components/icon'
import { Layout } from 'shared/components/layout'
import { Empty } from 'shared/components/empty'
import { PaymentFooter } from './footer'

export const SelectPayment = () => {
  const { id } = useParams()

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const order = useSelector(getOrderState)

  const getPaymentGroupListResult = useGetPaymentGroupListQuery()

  const [submitOrder, submitOrderResult] = useSubmitOrderMutation()

  const [selectedPayment, setSelectedPayment] = useState<string>()

  useEffect(() => {
    if (!submitOrderResult.isSuccess || !submitOrderResult.data) return

    const xenditUrl = submitOrderResult.data.xendit.invoiceAction.url

    if (!xenditUrl) {
      toast.error(L10N.LABEL.DATA_NOT_FOUND)
      return
    }

    dispatch(setPaymentUrl(xenditUrl))
    navigate(`/payment/${submitOrderResult.data.id}`)
  }, [submitOrderResult.data])

  const submitPayment = () => {
    if (!selectedPayment) return

    submitOrder({
      planId: Number(id),
      vin: localStorage.getItem(CONSTANT.VIN_KEY) ?? '',
      autoRenewal: order.isEnableAutoRenewal,
      isAgreeTnc: order.isAgreeTnc,
      isAgreePnp: order.isAgreePrivacyPolicy,
      paymentMethod: selectedPayment,
      promoCode: order.promoCode
    })
  }

  if (!order.isAgreeTnc || !order.isAgreePrivacyPolicy)
    return <Navigate to={`/subscription-plan/order/${id}`} />

  return (
    <Layout
      title={L10N.LABEL.PAYMENT_OPTIONS}
      type={LayoutType.HeaderFooter}
      footerComponent={
        <PaymentFooter
          total={order.paymentTotal}
          canSubmit={Boolean(selectedPayment)}
          submitLoading={submitOrderResult.isLoading}
          onSubmit={submitPayment}
        />
      }
    >
      <DataHandler {...getPaymentGroupListResult}>
        {getPaymentGroupListResult.data?.length ? (
          <>
            <h2 className='font-heading font-medium mb-4'>{L10N.LABEL.SELECT_PAYMENT_METHOD}</h2>

            <div>
              {getPaymentGroupListResult.data.map((payment, id) => (
                <div
                  key={id + 1}
                  className={[
                    'flex flex-row gap-2 items-center bg-white border transition p-3 mb-4 last:mb-0',
                    payment.value === selectedPayment ? 'border-secondary' : 'border-transparent'
                  ].join(' ')}
                  onClick={() => setSelectedPayment(payment.value)}
                >
                  <Icon icon={getIcon(payment.value)} size={20} />

                  <div className='flex flex-col flex-1'>
                    <div className='font-medium text-sm'>{payment.text || '-'}</div>
                    <div className='text-neutral text-xs'>{payment.description ?? ''}</div>
                  </div>

                  {payment.value === selectedPayment && (
                    <Icon icon='checkmark-filled' size={20} className='text-secondary' />
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <Empty />
        )}
      </DataHandler>
    </Layout>
  )
}

const getIcon = (paymentValue: string): string => {
  switch (paymentValue) {
    case 'VIRTUAL_ACCOUNT':
      return 'transfer'
    case 'EWALLET':
      return 'wallet'
    case 'CARD':
      return 'credit-card'
    default:
      return 'chevron-right'
  }
}
