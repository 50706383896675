import { L10N } from 'global/localization'
import type { TrxHistoryListItemProps } from 'global/types/props'
import type { TrxHistoryListItemResponse } from 'global/types/responses'
import { formatCurrency, formatDate, formatDateTime } from 'shared/services/utils'
import { StatusBadge } from 'shared/components/status-badge'
import Icon from 'shared/components/icon'

export const TrxHistoryListItem = ({ trxDetail, onClick }: TrxHistoryListItemProps) => (
  <button
    className='flex flex-col bg-white p-3 mb-4 last:mb-0 w-full text-left'
    type='button'
    onClick={() => onClick(trxDetail.id)}
  >
    <div className='flex flex-row gap-2 items-center mb-3 w-full'>
      <Icon icon='car' size={20} />
      <div className='flex flex-col flex-1'>
        <div className='font-medium text-sm'>{trxDetail.name}</div>
        <div className='text-neutral text-xs'>
          {trxDetail.status.toLowerCase() === 'paid' && trxDetail.paidDate
            ? formatDateTime(trxDetail.paidDate, true)
            : trxDetail.transactionDate
              ? formatDateTime(trxDetail.transactionDate, true)
              : '-'}
        </div>
      </div>
      <StatusBadge label={trxDetail.statusLabel} value={trxDetail.status} />
    </div>

    <div className='font-medium w-full'>{trxDetail.carModelName}</div>

    <div className='flex flex-row items-center justify-between w-full mb-3 last:mb-0'>
      <div className='text-neutral text-xs'>{trxDetail.invoiceNo}</div>
      <div className='text-sm text-right min-w-24'>{formatCurrency(trxDetail.price ?? 0)}</div>
    </div>

    {getAdditionalInfo(trxDetail)}
  </button>
)

const getAdditionalInfo = (trxDetail: TrxHistoryListItemResponse) => {
  switch (trxDetail.status.toLowerCase()) {
    case 'pending':
      return trxDetail.expiredDate ? (
        <div className='flex flex-row items-center justify-between w-full bg-background border border-border px-2 py-1'>
          <div className='text-sm'>{L10N.LABEL.EXPIRED_IN}</div>
          <div className='text-sm text-right'>{formatDateTime(trxDetail.expiredDate, true)}</div>
        </div>
      ) : (
        <></>
      )

    case 'paid':
      return trxDetail.isAutoRenewal ? (
        <div className='flex flex-row items-center justify-between w-full bg-secondary-background border border-secondary-border px-2 py-1'>
          <div className='text-sm'>{L10N.LABEL.AUTO_RENEWAL}</div>
          <div className='text-sm text-right'>
            {trxDetail.serviceEndDate ? formatDate(trxDetail.nextPaymentDate) : '-'}
          </div>
        </div>
      ) : (
        <></>
      )

    default:
      return <></>
  }
}
