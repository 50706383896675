import type { FetchArgs } from '@reduxjs/toolkit/query'
import { CONSTANT } from 'global/constant'
import { API } from 'global/api'
import type {
  ActiveCarResponse,
  PaginatedResponse,
  SubscriptionPackageResponse,
  DdlResponse,
  TrxHistoryListItemResponse,
  TrxHistoryDetailResponse,
  OrderDetailResponse,
  SubmitOrderResponse
} from 'global/types/responses'
import type {
  CancelOrderParam,
  OrderDetailParam,
  StopAutoRenewalParam,
  SubmitOrderParam,
  TrxHistoryParam
} from 'global/types/params'
import { api, headers } from './api'

const subscriptionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getActiveCar: builder.query<ActiveCarResponse, string>({
      query: (vin) => ({
        url: API.ACTIVE_CAR_URL.replace('{vin}', vin),
        method: 'GET',
        headers: headers()
      }),
      providesTags: [CONSTANT.CAR_TAG]
    }),
    getActivePackage: builder.query<PaginatedResponse<SubscriptionPackageResponse>, string>({
      query: (vin) => ({
        url: API.ACTIVE_PACKAGE_URL.replace('{vin}', vin),
        method: 'GET',
        headers: headers()
      }),
      providesTags: [CONSTANT.CAR_TAG]
    }),
    getPackageList: builder.query<SubscriptionPackageResponse[], string>({
      query: (vin) => ({
        url: API.PACKAGE_LIST_URL.replace('{vin}', vin),
        method: 'GET',
        headers: headers()
      }),
      transformResponse: (result: PaginatedResponse<SubscriptionPackageResponse>) => result.data
    }),
    getOrderDetail: builder.query<OrderDetailResponse, OrderDetailParam>({
      query: (param) => {
        const params = {
          vin: param.vin,
          promoCode: param.promoCode
        }

        return {
          url: API.ORDER_DETAIL_URL.replace('{planId}', param.planId),
          params,
          method: 'GET',
          headers: headers()
        }
      },
      transformResponse: (result: OrderDetailResponse) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const paymentFee: any = result.paymentFee

        if (paymentFee.total < 0) paymentFee.total = 0

        for (const key in result.paymentFee) {
          paymentFee[key] =
            typeof paymentFee[key] === 'number' ? Math.abs(paymentFee[key]) : paymentFee[key]
        }

        return { ...result, paymentFee }
      }
    }),
    submitOrder: builder.mutation<SubmitOrderResponse, SubmitOrderParam>({
      query: (param) => {
        const body = Object.keys(param)
          .filter((key) => key !== 'planId')
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .reduce((a, b) => ({ ...a, [b]: (param as any)[b] }), {})

        return {
          url: API.ORDER_DETAIL_URL.replace('{planId}', String(param.planId)),
          method: 'POST',
          headers: headers(),
          body
        }
      }
    }),
    cancelOrder: builder.mutation<unknown, CancelOrderParam>({
      query: (param) => {
        const body = {
          invoiceNo: param.invoiceNo
        }

        return {
          url: API.CANCEL_ORDER_URL.replace('{planId}', String(param.planId)),
          method: 'POST',
          headers: headers(),
          body
        }
      },
      invalidatesTags: [CONSTANT.TRANSACTION_TAG]
    }),
    stopAutoRenewal: builder.mutation<unknown, StopAutoRenewalParam>({
      query: (param) => {
        const body = {
          vin: param.vin
        }

        return {
          url: API.STOP_AUTO_RENEWAL_URL.replace('{planId}', String(param.planId)),
          method: 'POST',
          headers: headers(),
          body
        }
      }
    }),

    // Transaction
    getTrxHistory: builder.query<PaginatedResponse<TrxHistoryListItemResponse>, TrxHistoryParam>({
      query: (param) => {
        const params: FetchArgs['params'] = {
          ...param,
          length: CONSTANT.ITEMS_PER_PAGE
        }

        if (param.sort) {
          delete params.sort

          params['sort[0].field'] = param.sort.field
          params['sort[0].dir'] = param.sort.dir
        }

        return {
          url: API.TRX_HISTORY_URL,
          params,
          method: 'GET',
          headers: headers()
        }
      },
      providesTags: [CONSTANT.TRANSACTION_TAG]
    }),
    getTrxHistoryDetail: builder.query<TrxHistoryDetailResponse, string>({
      query: (id) => ({
        url: API.TRX_HISTORY_DETAIL_URL.replace('{id}', id),
        method: 'GET',
        headers: headers()
      }),
      providesTags: [CONSTANT.TRANSACTION_TAG],
      transformResponse: (result: TrxHistoryDetailResponse) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const paymentFee: any = result.paymentFee
        for (const key in result.paymentFee) {
          paymentFee[key] =
            typeof paymentFee[key] === 'number' ? Math.abs(paymentFee[key]) : paymentFee[key]
        }

        return { ...result, paymentFee }
      }
    }),

    // DDL data
    getPeriodList: builder.query<DdlResponse[], void>({
      query: () => ({
        url: API.PERIOD_LIST_URL,
        method: 'GET',
        headers: headers()
      })
    }),
    getPaymentGroupList: builder.query<DdlResponse[], void>({
      query: () => ({
        url: API.PAYMENT_GROUP_LIST_URL,
        method: 'GET',
        headers: headers()
      })
    }),
    getPaymentMethodList: builder.query<DdlResponse[], void>({
      query: () => ({
        url: API.PAYMENT_METHOD_LIST_URL,
        method: 'GET',
        headers: headers()
      })
    }),
    getPackageNameList: builder.query<DdlResponse[], void>({
      query: () => ({
        url: API.PACKAGE_NAME_LIST_URL,
        method: 'GET',
        headers: headers()
      })
    }),
    getTrxStatusList: builder.query<DdlResponse[], void>({
      query: () => ({
        url: API.TRX_STATUS_LIST_URL,
        method: 'GET',
        headers: headers()
      })
    })
  })
})

export const {
  useGetActiveCarQuery,
  useLazyGetActiveCarQuery,
  useLazyGetActivePackageQuery,
  useGetPackageListQuery,
  useLazyGetPackageListQuery,
  useLazyGetOrderDetailQuery,
  useSubmitOrderMutation,
  useCancelOrderMutation,
  useStopAutoRenewalMutation,

  // Transaction
  useLazyGetTrxHistoryQuery,
  useLazyGetTrxHistoryDetailQuery,

  // DDL data
  useGetPeriodListQuery,
  useGetPaymentGroupListQuery,
  useLazyGetPaymentMethodListQuery,
  useLazyGetPackageNameListQuery,
  useGetTrxStatusListQuery,
  useLazyGetTrxStatusListQuery
} = subscriptionApi
