import ReactModal from 'react-modal'
import type { InfoModalProps } from 'global/types/props'
import { L10N } from 'global/localization'
import { Button } from 'shared/components/button'

export const InfoModal = ({
  title,
  desc,
  confirmText = L10N.LABEL.OK,
  confirmColor,
  open,
  onConfirm
}: InfoModalProps) => (
  <ReactModal
    isOpen={open}
    onRequestClose={onConfirm}
    contentLabel={title}
    shouldCloseOnOverlayClick={false}
    closeTimeoutMS={300}
    bodyOpenClassName='modal-open'
    overlayClassName='modal-overlay'
    className='confirm-modal'
  >
    {title && desc && <h3 className='font-heading font-medium text-lg p-4 pb-0'>{title}</h3>}

    <div className='p-4 text-sm'>{desc || title}</div>

    <div className='border grid grid-cols-1'>
      <Button label={confirmText} color={confirmColor} onClick={onConfirm} />
    </div>
  </ReactModal>
)
