import type { CardProps } from 'global/types/props'
import { Button } from 'shared/components/button'

export const Card = ({ bannerImage, useBgPattern, actions, onClick, children }: CardProps) => {
  const gridCols = ['grid-cols-1', 'grid-cols-2']

  const getContent = () => (
    <>
      {bannerImage && (
        <div className='flex items-end justify-center h-48 px-8 bg-transparent'>
          <img
            src={bannerImage}
            alt='card-banner'
            className='object-contain max-h-48'
            loading='lazy'
          />
        </div>
      )}
      <div
        className={`bg-white bg-[100%_auto] bg-no-repeat bg-top border border-border ${bannerImage ? '-mt-16 pt-16' : ''}`}
        style={useBgPattern ? { backgroundImage: "url('/package-pattern.png')" } : undefined}
      >
        <div className={`p-4 ${bannerImage ? 'pt-0' : ''}`}>{children}</div>

        {actions && Boolean(actions.length) && (
          <div
            className={`grid border-t border-t-border gap-px bg-border ${actions.length > 2 ? gridCols[1] : gridCols[actions.length - 1]}`}
          >
            {actions.map(({ name, color, onClick }, id) => (
              <Button key={id + 1} label={name} color={color} onClick={onClick} />
            ))}
          </div>
        )}
      </div>
    </>
  )

  if (onClick)
    return (
      <button className='w-full text-left mb-4 last:mb-0' type='button' onClick={onClick}>
        {getContent()}
      </button>
    )

  return <div className='mb-4 last:mb-0'>{getContent()}</div>
}
