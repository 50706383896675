import type { ActiveCarCardProps, CarListItemCardProps, CardProps } from 'global/types/props'
import { ButtonColor, SubscriptionStatus } from 'global/types/enums'
import { L10N } from 'global/localization'
import { Card } from 'shared/components/card'
import { SubscriptionBadge } from 'shared/components/subscription-badge'
import Icon from 'shared/components/icon'
import { formatDate } from 'shared/services/utils'

export const CarCard = ({
  type,
  car,
  hideImage = false,
  hideSubscription = false,
  hideAutoRenewal = false,
  onDetail,
  onHistory,
  onSubscribe
}: ActiveCarCardProps | CarListItemCardProps) => {
  const getActions = (): CardProps['actions'] => {
    const actions: CardProps['actions'] = []

    if (type === 'car-list-item') {
      if (onDetail) actions.push({ name: L10N.LABEL.DETAIL, onClick: () => onDetail(car) })
      if (onHistory) actions.push({ name: L10N.LABEL.HISTORY, onClick: () => onHistory(car) })
      if (onSubscribe)
        actions.push({
          name: L10N.LABEL.SUBSCRIBE_NOW,
          color: ButtonColor.Primary,
          onClick: () => onSubscribe(car)
        })
    }

    return actions
  }

  return (
    <Card
      bannerImage={!hideImage ? (car.imageCar ?? '/unknown-car.png') : undefined}
      actions={getActions()}
    >
      <div className='flex flex-row gap-1 items-center justify-between mb-3'>
        <h2 className='font-heading font-medium text-xl'>{car.carModelName ?? '???'}</h2>
        {!car.isMater && (
          <div className='flex flex-row gap-1 text-primary'>
            <Icon icon='share' size={24} />
            <span>{L10N.LABEL.SHARED}</span>
          </div>
        )}
      </div>

      <div className='flex flex-row mb-3 last:mb-0'>
        <div className='flex-1'>
          <div className='text-neutral text-xs'>{L10N.LABEL.REGISTERED_OWNER}</div>
          <div className='font-heading text-sm'>
            {(type === 'active-car' ? car.customerName : car.customerFullname) ?? '-'}
          </div>
        </div>
        <div className='flex-1'>
          <div className='text-neutral text-xs'>{L10N.LABEL.VIN}</div>
          <div className='font-heading text-sm'>{car.vin ?? '-'}</div>
        </div>
      </div>

      {!hideSubscription && (
        <div className='mb-3 last:mb-0'>
          {(
            type === 'active-car'
              ? car.statusActivation !== SubscriptionStatus.Inactive
              : car.subscriptionStatusActivation !== SubscriptionStatus.Inactive
          ) ? (
            <SubscriptionBadge
              text={
                type === 'active-car'
                  ? car.statusDescription
                  : (car.subscriptionStatusDescription ?? '-')
              }
              subText={
                type === 'active-car'
                  ? car.expiredDate
                    ? formatDate(car.expiredDate)
                    : undefined
                  : car.subscriptionExpiredDate
                    ? formatDate(car.subscriptionExpiredDate)
                    : undefined
              }
            />
          ) : (
            <SubscriptionBadge text={L10N.LABEL.VEHICLE_HAS_NOT_SUBSCRIBED} color='bg-background' />
          )}
        </div>
      )}

      {!hideAutoRenewal && (
        <>
          {type === 'active-car' && car.statusActivation !== SubscriptionStatus.Inactive && car.isRenewal && (
            <div className='flex flex-row items-center px-3 py-1 gap-3 bg-background border border-border'>
              <div>
                <Icon icon='calendar-check' size={24} />
              </div>
              <div className='flex flex-col'>
                <h3 className='text-sm'>{L10N.LABEL.AUTO_RENEWAL}</h3>
                <div className='text-neutral text-xs'>
                  {L10N.formatString(
                    L10N.MESSAGE.AUTO_RENEWAL_DESC,
                    formatDate(car.nextPaymentDate),
                    car.duration.toLowerCase() === 'monthly'
                      ? L10N.LABEL.MONTH.toLowerCase()
                      : L10N.LABEL.YEAR.toLowerCase()
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Card>
  )
}
